// @ts-check
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {Box, Button, FormControlLabel, IconButton, Popover, Switch, TextField} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import {PickersDay, pickersDayClasses, StaticDatePicker as MaterialDatePicker} from "@mui/x-date-pickers";
import {bool, func, object} from "prop-types";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import config from "../../../../config/config.json";
import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {selectShowTeaser} from "../../../redux/events/event_selectors";
import useStyles from "./week_selector.styles";

/** @typedef {import("@mui/material").TextFieldProps} TextFieldProps */

/**
 * WeekSelector component
 * @param {Object} props
 * @param {DateTimeType} props.from DateTime object
 * @param {DateTimeType} props.to DateTime object
 * @param {Function} props.onChange
 * @param {Function} props.onToggleShowWeekend
 * @param {Boolean} props.showWeekend
 * @return {React.ReactElement}
 */
const WeekSelector = ({from, showWeekend, to, onChange, onToggleShowWeekend}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const theme = useTheme();
    const {minusDT, plusDT, areSame, startOf, endOf, format, now, getDT} = useContext(DateContext);

    // redux
    const showTeaser = useSelector(selectShowTeaser);

    // States
    const [hidetooltip, setHidetooltip] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [fromDate, setFromDate] = useState(from);
    const [toDate, setToDate] = useState(to);
    const [showWeekendState, setShowWeekendState] = useState(showWeekend);

    const upSM = useMediaQuery(theme.breakpoints.up("sm"));

    useEffect(() => {
        setFromDate(from);
    }, [from]);

    useEffect(() => {
        if (!showWeekendState && getDT(to, "weekday") === 7) {
            setToDate(minusDT(to, "day", 2));
        } else if (!showWeekendState && getDT(to, "weekday") === 6) {
            setToDate(minusDT(to, "day", 1));
        } else {
            setToDate(to);
        }
    }, [to]);

    const handleForwardClick = () => onChange(plusDT(from, "week", 1));
    const handleBackClick = () => onChange(minusDT(from, "week", 1));

    const renderWrappedWeekDay = (date, _, pickersDayProps) => {
        const isFirstDay = areSame(date, fromDate, "day");
        const isLastDay = areSame(date, toDate, "day");
        const dayIsBetween = (startOf(fromDate, "day") <= date && date <= endOf(toDate, "day")) || isFirstDay || isLastDay;

        return (
            <div
                className={cx({
                    [classes.highlight]: dayIsBetween,
                    [classes.firstHighlight]: isFirstDay,
                    [classes.endHighlight]: isLastDay
                })}
                key={format(date, DATE_FORMATS.DATE)}
            >
                <PickersDay
                    {...pickersDayProps}
                    selected={dayIsBetween || isLastDay}
                    showDaysOutsideCurrentMonth={isFirstDay || dayIsBetween || isLastDay}
                    sx={{
                        [`&&.${pickersDayClasses.selected}`]: {
                            backgroundColor: theme.palette.primary.main
                        },
                        fontSize: "15px"
                    }}
                />
            </div>
        );
    };

    const getTooltip = () => (!upSM && !hidetooltip ? t("WeekSelector.calendarWeek", {num: getDT(fromDate, "weekNumber")}) : "");

    /**
     * handler to open popover (Datepicker)
     * @param {Object} event
     */
    const handleOpenDatePicker = (event) => {
        setHidetooltip(true);
        setAnchorEl(event.currentTarget);
    };

    /**
     * handler to close popover (Datepicker)
     */
    const handleCloseDatePicker = () => {
        setAnchorEl(null);
        setHidetooltip(false);
    };

    /**
     * save changedDate in state
     * @param {DateTimeType} date - DateTime object
     */
    const handleChange = (date) => {
        setFromDate(startOf(date, "week"));

        const addDays = showWeekendState ? 6 : 4;
        setToDate(plusDT(startOf(date, "week"), "day", addDays));
    };
    /**
     * handler to toggle showWeekendState
     */
    const handleToggleWeekendState = () => {
        setShowWeekendState(!showWeekendState);
        if (!showWeekendState) {
            setToDate(plusDT(fromDate, "day", 6));
        } else {
            setToDate(plusDT(fromDate, "day", 4));
        }
    };

    /**
     * apply date
     */
    const handleApplyDate = () => {
        setAnchorEl(null);
        onChange(fromDate);
        if (showWeekend !== showWeekendState) {
            onToggleShowWeekend();
        }
    };

    return (
        <Tooltip title={getTooltip()}>
            <Box
                className={cx(classes.root, {
                    [classes.showTeaser]: showTeaser
                })}
            >
                <IconButton
                    className={classes.iconButton}
                    data-testid="back"
                    size="small"
                    title={t("WeekSelector.back")}
                    onClick={handleBackClick}
                >
                    <ChevronLeftIcon className={classes.icon} />
                </IconButton>
                <div
                    className={classes.dateLabel}
                    role={"button"}
                    tabIndex={0}
                    onClick={handleOpenDatePicker}
                    onKeyDown={(e) => e.key === "Enter" && handleOpenDatePicker(e)}
                >
                    {upSM
                        ? t("WeekSelector.calendarWeek", {num: getDT(fromDate, "weekNumber")})
                        : t("WeekSelector.calendarWeekShort", {num: getDT(fromDate, "weekNumber")})}
                </div>
                <IconButton
                    className={classes.iconButton}
                    data-testid="forward"
                    size="small"
                    title={t("WeekSelector.forward")}
                    onClick={handleForwardClick}
                >
                    <ChevronRightIcon className={classes.icon} />
                </IconButton>
                <Popover
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    classes={{paper: classes.popover}}
                    open={Boolean(anchorEl)}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    onClose={handleCloseDatePicker}
                >
                    <MaterialDatePicker
                        disableHighlightToday={true}
                        displayStaticWrapperAs="desktop"
                        maxDate={plusDT(now(), "year", config.MAX_DATE_IN_YEARS)}
                        minDate={minusDT(now(), "year", config.MIN_DATE_IN_YEARS)}
                        renderDay={renderWrappedWeekDay}
                        // @ts-ignore couldn't solve error if TextField props
                        renderInput={(params /** @param {TextFieldProps} params */) => <TextField variant="standard" {...params} />}
                        value={fromDate}
                        onChange={handleChange}
                    />
                    <div className={classes.switchWrapper}>
                        <FormControlLabel
                            control={<Switch checked={showWeekendState} color="primary" size="small" onChange={handleToggleWeekendState} />}
                            label={<div className={classes.switchLabel}>{t("WeekSelector.showWeekend")}</div>}
                            labelPlacement="start"
                        />
                    </div>
                    <div className={classes.buttonWrapper}>
                        <Button color="primary" size="small" variant="outlined" onClick={handleCloseDatePicker}>
                            {t("WeekSelector.cancel")}
                        </Button>
                        <Button color="primary" size="small" variant="contained" onClick={handleApplyDate}>
                            {t("WeekSelector.submit")}
                        </Button>
                    </div>
                </Popover>
            </Box>
        </Tooltip>
    );
};

WeekSelector.propTypes = {
    from: object.isRequired, // DateTime object
    showWeekend: bool.isRequired,
    to: object.isRequired,
    onChange: func.isRequired,
    onToggleShowWeekend: func
};

export default WeekSelector;
