import {Info} from "@mui/icons-material";
import {Button} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import useStyles from "./no_op.styles";

/**
 * Op not found page
 * @return {JSX}
 */
const NoOp = ({text1, text2, actionText, onClick, height}) => {
    const {classes} = useStyles();
    return (
        <div className={classes.root} data-testid="noOperations" style={{height: height}}>
            <Info className={classes.icon} color="primary" />
            <div className={classes.header}>
                <div>{text1}</div>
                <div>
                    {text2}
                    {actionText && typeof onClick === "function" && (
                        <Button className={classes.buttonText} size="small" onClick={onClick}>
                            {actionText}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

NoOp.propTypes = {
    text1: PropTypes.node,
    text2: PropTypes.node,
    actionText: PropTypes.string,
    onClick: PropTypes.func,
    height: PropTypes.string
};
export default NoOp;
