/**
 * @fileoverview text field component
 *
 * usage:
 * <code>
 * </code>
 */

import {Search} from "@mui/icons-material";
import {InputAdornment, TextField} from "@mui/material";
import {Autocomplete} from "@mui/material";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import useStyles from "./search_text_field.styles";

const SearchTextField = ({
    title,
    onChange,
    styles,
    placeholder,
    onEnterKey,
    showSearchIcon,
    variant,
    disableUnderline,
    shrink,
    options,
    onDelete,
    value,
    filterOptions
}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const [active, setActive] = useState(false);
    const [open, setOpen] = useState(false);

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && typeof onEnterKey === "function") {
            onEnterKey(value);
        }
    };
    const handleOpen = (e) => {
        if (e.target.value) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    };
    const handleClose = (e) => {
        setOpen(false);
    };

    const handleChange = (e, value) => {
        if (!value && !e.target.value) {
            setActive(false);
            if (typeof onDelete === "function") {
                onDelete();
            }
        } else {
            setActive(true);
        }

        if (e.key === "Enter" && typeof onEnterKey === "function") {
            onEnterKey(value || e.target.value || "");
        }
        onChange(value || e.target.value || "");
    };
    const handleActive = () => {
        if (!active) {
            setActive(true);
        }
    };
    const handleBlur = () => {
        setActive(false);
    };
    return (
        <div
            className={cx(classes.root, {
                [classes.rootFocused]: active,
                [classes.ml05]: !showSearchIcon
            })}
        >
            <Autocomplete
                classes={{
                    option: classes.option,
                    paper: classes.paper,
                    clearIndicator: classes.clearIndicator
                }}
                closeText={t("App.close")}
                filterOptions={filterOptions}
                freeSolo
                inputValue={value} // fix for click enter
                open={open && active}
                openText={t("App.open")}
                options={options.map((option) => option.name)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        className={cx({
                            [classes.inputWrapper]: !styles.inputWrapper,
                            [styles.inputWrapper]: Boolean(styles.inputWrapper)
                        })}
                        InputLabelProps={{shrink: shrink}}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: showSearchIcon && (
                                <InputAdornment position="start">
                                    <Search className={classes.searchIcon} color="primary" fontSize="small" />
                                </InputAdornment>
                            ),
                            disableUnderline: disableUnderline
                        }}
                        inputProps={{
                            ...params.inputProps,
                            "className": cx({
                                [classes.input]: !styles.input,
                                [styles.input]: Boolean(styles.input),
                                [classes.inputFocused]: active
                            }),
                            "data-testid": "search-text-field"
                        }}
                        label={title}
                        placeholder={placeholder}
                        variant={variant}
                        onClick={handleActive}
                        onKeyDown={handleKeyDown} // this handler should be set in TextField not in the Autocomplete, so that the handleChange is called after handleKeyDown
                    />
                )}
                onBlur={handleBlur}
                onChange={(e, option, type) => {
                    if (type !== "clear") {
                        onChange(option);
                    }
                    if (type === "clear") {
                        onChange("");
                        setActive(false);
                        if (typeof onDelete === "function") {
                            onDelete();
                        }
                        setOpen(false);
                    }
                }}
                onClose={handleClose}
                onInputChange={handleChange}
                onOpen={handleOpen}
            />
        </div>
    );
};

SearchTextField.propTypes = {
    styles: PropTypes.shape({
        input: PropTypes.string,
        root: PropTypes.string,
        inputWrapper: PropTypes.string
    }),
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    title: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    onEnterKey: PropTypes.func,
    onDelete: PropTypes.func,
    showSearchIcon: PropTypes.bool,
    variant: PropTypes.string,
    disableUnderline: PropTypes.bool,
    shrink: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
        })
    ),
    value: PropTypes.string,
    filterOptions: PropTypes.func // optional
};

SearchTextField.defaultProps = {
    styles: {},
    variant: "standard"
};

export default SearchTextField;
