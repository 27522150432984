/**
 * @fileOverview Tooltip wrapper for ui elements that are disabled because of a missing permission
 */

import {Tooltip} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";

const PermissionTooltip = ({children, isAllowed, title}) => {
    const {t} = useTranslation();

    if (isAllowed && !title) {
        return children;
    }

    if (isAllowed) {
        return <Tooltip title={title}>{children}</Tooltip>;
    }

    return (
        <Tooltip title={isAllowed ? title : t("App.missingPermission")}>
            <span>{children}</span>
        </Tooltip>
    );
};

PermissionTooltip.propTypes = {
    children: PropTypes.node.isRequired,
    isAllowed: PropTypes.bool.isRequired,
    title: PropTypes.string
};

export default PermissionTooltip;
