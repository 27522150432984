import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    timeLine: {
        backgroundColor: theme.palette.grey[500],

        display: "flex",
        alignItems: "stretch"
    },
    height: {
        height: "0.25rem"
    },
    width: {
        width: "0.25rem"
    },
    prep: {
        backgroundColor: theme.custom.preparation.main
    },
    op: {
        backgroundColor: theme.custom.progress.main
    },
    post: {
        backgroundColor: theme.custom.post.main
    },
    noDurations: {
        backgroundColor: theme.palette.grey[300]
    }
}));
