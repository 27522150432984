// @ts-check
import {object} from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";

import config from "../../../../config/config.json";

/**
 * render Ward component
 * @param {Object} props
 * @param {{before: Record<string, string>, after: Record<string, string>}} props.patientLocations
 * @return {React.ReactElement}
 */
const Ward = ({patientLocations}) => {
    const {t} = useTranslation();

    const {
        TEXT_PUNCTUATION: {HYPHEN}
    } = config;
    const {before, after} = patientLocations || {};
    const text = t("Ward.fromTo", {from: before?.ward || HYPHEN, to: after?.ward || HYPHEN});
    return (
        <span data-testid={"ward"} title={text}>
            {text}
        </span>
    );
};
Ward.propTypes = {
    patientLocations: object
};
export default Ward;
