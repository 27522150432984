import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    root: {
        "display": "flex",
        "flexDirection": "column",
        "justifyContent": "center",
        "alignItems": "center",
        "color": theme.palette.grey[500],
        "height": "100%",
        "& .MuiIconButton-root:hover": {
            backgroundColor: theme.palette.primary.main
        }
    },
    header: {
        fontSize: theme.spacing("m"),
        fontWeight: theme.typography.fontWeightBold,
        margin: "0.6rem",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    buttonText: {
        marginTop: theme.spacing("xs"),
        fontSize: theme.spacing("m"),
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main
    },
    icon: {
        fontSize: "8rem"
    }
}));
