import {Switch, Tooltip} from "@mui/material";
import PropTypes from "prop-types";
import React, {Fragment} from "react";

import useStyles from "./view_switch.styles";

const ViewSwitch = ({isChecked, onToggle, labels, title}) => {
    const {classes, cx} = useStyles();

    return (
        <Fragment>
            <span
                className={cx({
                    [classes.on]: !isChecked
                })}
            >
                {labels.left}
            </span>
            <Tooltip arrow title={title}>
                <Switch checked={isChecked} color="primary" onChange={onToggle} />
            </Tooltip>
            <span
                className={cx({
                    [classes.on]: isChecked
                })}
            >
                {labels.right}
            </span>
        </Fragment>
    );
};

ViewSwitch.propTypes = {
    isChecked: PropTypes.bool,
    onToggle: PropTypes.func.isRequired,
    labels: PropTypes.shape({
        left: PropTypes.string.isRequired,
        right: PropTypes.string
    }).isRequired,
    title: PropTypes.string
};

export default ViewSwitch;
