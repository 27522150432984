import {InfoOutlined} from "@mui/icons-material";
import {Tooltip} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

import useStyles from "./password_rules.styles";

const PasswordRules = () => {
    const {classes} = useStyles();
    const {t} = useTranslation();

    const content = (
        <div className={classes.passwordRulesTooltip}>
            {t("Account.password_rules")}
            <ul>
                <li key="rule-1">{t("Account.password_rule1")}</li>
                <li key="rule-2">{t("Account.password_rule2")}</li>
                <li key="rule-3">{t("Account.password_rule3")}</li>
                <li key="rule-4">{t("Account.password_rule4")}</li>
            </ul>
        </div>
    );
    return (
        <div className={classes.passwordRulesWrapper}>
            <div className={classes.passwordRules}>
                <Tooltip title={content}>
                    <InfoOutlined className={classes.icon} />
                </Tooltip>
                <span className={classes.iconText}>{t("Account.password_rules")}</span>
            </div>
        </div>
    );
};

export default PasswordRules;
