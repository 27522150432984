import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    root: {
        "& .MuiIconButton-root": {
            padding: 0
        },
        "& .MuiSvgIcon-root": {
            fontSize: theme.spacing("m")
        },
        "& .MuiInputBase-root": {
            cursor: "pointer",
            paddingRight: 0
        },
        "& .MuiAutocomplete-endAdornment": {
            marginRight: theme.spacing("s"),
            top: theme.spacing("xxs")
        }
    },
    ml05: {
        marginRight: theme.spacing("s")
    },
    rootFocused: {
        color: theme.palette.grey[700],
        backgroundColor: theme.palette.common.white,
        borderRadius: "3px"
    },
    input: {
        "overflow": "hidden",
        "textOverflow": "ellipsis",
        "width": "70%",
        "lineHight": theme.spacing("m"),
        "fontSize": "0.875rem",
        "color": theme.palette.common.white,
        "cursor": "pointer",
        "paddingLeft": theme.spacing("s"),
        "&::placeholder": {
            opacity: 1,
            color: theme.palette.grey[500]
        }
    },
    inputFocused: {
        backgroundColor: theme.palette.common.white,
        borderRadius: "3px",
        cursor: "text"
    },
    iconColor: {
        color: theme.palette.common.white
    },
    inputWrapper: {
        "width": "12.5rem",
        "& svg": {
            fontSize: theme.spacing("m")
        }
    },
    option: {
        fontSize: "0.875rem"
    },
    paper: {
        width: "15rem"
    },
    clearIndicator: {
        border: `1px solid ${theme.palette.grey[200]}`
    },
    searchIcon: {
        cursor: "default"
    }
}));
