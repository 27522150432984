// @ts-check
import {Close} from "@mui/icons-material";
import {DialogTitle} from "@mui/material";
import {func, object, string} from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";

import config from "../../../../config/config.json";
import useStyles from "./op_dialog_title.styles";

/**
 * render OpDialogTitle component showing
 * @param {Object} props
 * @param {PlanBoxHealthcareService} props.healthcareService
 * @param {string} [props.encounterId]
 * @param {function} props.onClose
 * @return {React.ReactElement}
 */
const OpDialogTitle = ({healthcareService, encounterId, onClose}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();

    const {
        TEXT_PUNCTUATION: {HYPHEN}
    } = config;

    return (
        <DialogTitle classes={{root: classes.title}}>
            <span>
                <span className={classes.encounterId}>{`${t("OpDialogTitle.encounterId")}: ${encounterId || HYPHEN}`}</span>
                {t(`HealthcareService.${healthcareService?.reference}`, healthcareService?.display)}
            </span>
            <Close className={classes.icon} onClick={() => onClose()} />
        </DialogTitle>
    );
};

OpDialogTitle.propTypes = {
    healthcareService: object,
    encounterId: string,
    onClose: func
};
export default OpDialogTitle;
