const Environment = {
    Production: "production",
    Local: "local",
    Remote: "remote",
    Development: "dev",
    Stage: "stage",
    QA: "qa"
};

/**
 * detect prod environment
 * @param {string} namespace    the service namespace
 * @return {boolean}
 */
const isProdEnvironment = (namespace) =>
    namespace.endsWith(`-${Environment.Production}`) || namespace.includes(`-${Environment.Production}-`);

/**
 * detect any nonprod environment
 * @param {string} namespace    the service namespace
 * @return {boolean}
 */
const isNonProdEnvironment = (namespace) =>
    namespace.includes(`-${Environment.Remote}-`) ||
    [Environment.Local, Environment.Remote, Environment.Development, Environment.QA].some((environment) =>
        namespace.endsWith(`-${environment}`)
    );

/**
 * detect customer stage environment, e.g. with namespace nextor-dev-dhm
 * @param {string} namespace    the service namespace
 * @return {*|boolean}
 */
const isStageEnvironment = (namespace) =>
    namespace.includes(`-${Environment.Stage}`) ||
    (namespace.includes(`-${Environment.Development}`) && !namespace.endsWith(`-${Environment.Development}`));

/**
 * get the name of the current environment
 * examples:
 *   - production
 *   - dev
 *   - dhm-dev
 *   - local
 *   - remote-developer
 *
 * @param {string} namespace    the service namespace
 * @return {null|string}
 */
const getEnvironmentName = (namespace) => {
    if (isProdEnvironment(namespace)) {
        return Environment.Production;
    }

    if (isStageEnvironment(namespace)) {
        return Environment.Stage;
    }

    if (isNonProdEnvironment(namespace)) {
        return Object.values(Environment).find((environment) => namespace.includes(`-${environment}`));
    }

    return null;
};

export {Environment, getEnvironmentName};
