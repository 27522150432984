import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    passwordRulesWrapper: {
        display: "flex"
    },
    passwordRules: {
        color: theme.palette.primary.main,
        display: "flex",
        fontSize: "14px"
    },
    passwordRulesTooltip: {
        fontSize: "0.7rem",
        color: theme.palette.primary.contrastText,
        padding: theme.spacing("s")
    },
    icon: {
        fontSize: "24px",
        position: "relative",
        top: "-1px",
        left: "-8px",
        color: `${theme.palette.grey[700]}`
    },
    iconText: {
        whiteSpace: "nowrap"
    }
}));
