/**
 * @fileOverview html page head
 */

import PropTypes from "prop-types";
import React from "react";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {selectAlias} from "../../fe_settings/fe_settings_selectors";
import {Environment, getEnvironmentName} from "./head_utils";

const Head = ({title}) => {
    const {t} = useTranslation();
    const selectedAlias = useSelector(selectAlias);
    const environment = getEnvironmentName(global.SERVICE_NAMESPACE || "");
    const alias = environment && environment !== Environment.Production ? `${selectedAlias}_${environment}` : selectedAlias;

    const pageTitle = title?.length ? t("App.pageTitle", {title, alias: alias}) : t("App.title", {alias});

    return (
        <Helmet>
            <title>{pageTitle}</title>
        </Helmet>
    );
};

Head.propTypes = {
    title: PropTypes.string
};

export default Head;
