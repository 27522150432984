import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    root: {
        "left": "50%",
        "transform": "translate(-50%, 0)",
        "borderBottomRightRadius": "5px",
        "borderBottomLeftRadius": "5px",
        "zIndex": theme.zIndex.modal,
        "position": "fixed",
        "top": 0,
        "display": "flex",
        "alignItems": "center",
        "height": "2.5rem",
        "backgroundColor": theme.palette.primary.main,
        "padding": 0,
        "& .MuiIconButton-sizeSmall": {
            padding: 0
        }
    },
    iconButton: {
        width: "1.5em"
    },
    icon: {
        color: theme.palette.primary.contrastText
    },
    dayWrapper: {
        position: "relative"
    },
    day: {
        width: 36,
        height: 36,
        fontSize: "1rem",
        margin: "0 2px",
        color: "inherit"
    },
    highlight: {
        "background": theme.palette.primary.main,
        "& .button": {
            background: theme.palette.primary.main,
            color: theme.palette.common.white
        }
    },
    firstHighlight: {
        extend: "highlight",
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius
    },
    endHighlight: {
        extend: "highlight",
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius
    },
    dateLabel: {
        color: theme.palette.primary.contrastText,
        fontWeight: "bold",
        textTransform: "uppercase",
        fontSize: theme.spacing("m"),
        cursor: "pointer"
    },
    popover: {
        padding: theme.spacing("l"),
        background: "#fff"
    },
    switchWrapper: {
        margin: "1rem 0"
    },
    switchLabel: {
        fontSize: "0.875rem"
    },
    buttonWrapper: {
        margin: "1rem 0",
        display: "flex",
        justifyContent: "space-between"
    },
    showTeaser: {
        top: "30px"
    }
}));
