import {makeStyles} from "tss-react/mui";

/**
 * @type {object}
 */
export default makeStyles()((theme) => ({
    marginBetween: {
        // margin between dateRange, timeRange
        marginLeft: theme.spacing("s")
    },
    mtEnd: {
        // marginTop on end item (dateRange, timeRange)
        marginTop: theme.spacing("m")
    },
    to: {
        display: "inline",
        top: "1.2rem",
        left: "-0.2rem",
        position: "relative",
        fontSize: "0.688rem",
        color: theme.palette.primary.main
    },
    disabled: {
        color: theme.palette.text.disabled
    },
    switch: {
        "& .MuiFormControlLabel-root": {
            margin: "-0.25rem",
            padding: `${theme.spacing("xs")} 0`
        },
        "& .MuiSwitch-thumb": {
            color: theme.palette.primary.main
        }
    },
    switchText: {
        fontSize: "0.875rem",
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.text.primary
    },
    inputDate: {
        "fontSize": "0.875rem",
        "color": theme.palette.text.primary,
        "height": "1.7rem",
        "& .MuiIconButton-root": {
            padding: 0
        },
        "& .MuiSvgIcon-root": {
            fontSize: "1.5rem",
            color: theme.palette.primary.main
        },
        "width": "7rem"
    }
}));
