// @ts-check
import Box from "@mui/material/Box";
import {bool, number, shape} from "prop-types";
import React from "react";

import useStyles from "./op_time_line.styles";

/**
 * OpTimeLine component
 * @param {Object} props
 * @param {InternalTimestampsOpData} props.duration
 * @param {Boolean} [props.isHorizontal]
 * @return {React.ReactElement}
 */
const OpTimeLine = ({duration, isHorizontal}) => {
    const {classes, cx} = useStyles();

    // Set timestamp line

    let percentUndefined = 100;
    let percentageTimePreOp = 0;
    let percentageTimePostOp = 0;
    let percentageTimeOp = 0;

    if (duration && duration.duraRoomLockPre && duration.duraRoomLockOp && duration.duraRoomLockPost) {
        percentageTimePreOp = (duration.duraRoomLockPre.refEnd / duration.duraRoomLockPost.refEnd) * 100;
        percentageTimeOp = ((duration.duraRoomLockOp.refEnd - duration.duraRoomLockPre.refEnd) / duration.duraRoomLockPost.refEnd) * 100;
        percentageTimePostOp =
            ((duration.duraRoomLockPost.refEnd - duration.duraRoomLockOp.refEnd) / duration.duraRoomLockPost.refEnd) * 100;
        percentUndefined = 0;
    }
    const timeRow = (
        <div
            className={cx(classes.timeLine, {
                [classes.height]: !isHorizontal,
                [classes.width]: isHorizontal
            })}
        >
            <Box className={classes.prep} width={percentageTimePreOp + "%"} />
            <Box className={classes.op} width={percentageTimeOp + "%"} />
            <Box className={classes.post} width={percentageTimePostOp + "%"} />
            <Box className={classes.noDurations} width={percentUndefined + "%"} />
        </div>
    );

    return timeRow;
};
const timestampElement = {
    refStart: number,
    refEnd: number
};

OpTimeLine.propTypes = {
    duration: shape({
        duraRoomLockPre: shape(timestampElement),
        duraRoomLockOp: shape(timestampElement),
        duraRoomLockPost: shape(timestampElement)
    }),
    isHorizontal: bool
};

OpTimeLine.defaultProps = {
    isHorizontal: false
};

export default OpTimeLine;
