import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    root: {
        background: theme.palette.background.default,
        fontSize: "14px",
        height: "100vh"
    },
    verticalLine: {
        width: "6px",
        height: "100vh",
        position: "fixed",
        zIndex: theme.zIndex.drawer
    },
    content: {
        background: theme.palette.background.default,
        flexGrow: 1,
        left: theme.custom.menuWidth,
        width: `calc(100% - ${theme.custom.menuWidth})`
    },
    contentMaxMainMenu: {
        width: `calc(100% - ${theme.custom.menuWidthMax}) !important`,
        left: theme.custom.menuWidthMax
    },
    contentView: {
        position: "absolute",
        top: theme.custom.headerHeight,
        height: `calc(100% - 3rem)`,
        padding: `2rem 6.5rem ${theme.spacing("xxl")} ${theme.spacing("xxl")}`
    },
    contentNoPadding: {
        position: "absolute",
        height: `calc(100% - ${theme.custom.headerHeight})`,
        overflow: "auto"
    },
    fullActionMenubar: {
        paddingRight: "9.25rem !important" // must be 6.5rem + action menubar width
    },
    fullActionMenubarWithRightLayer: {
        paddingRight: "40.5rem !important"
    },
    openRightLayer: {
        paddingRight: "35.25rem !important" // must be right action bar + right layer width
    },
    fullscreenWithRightLayer: {
        paddingRight: "31.25rem !important" // must be right layer
    },
    fullCanvas: {
        padding: "0 4rem 0 0"
    },
    contentViewHeightMinPadding: {
        position: "absolute",
        height: `calc(100% - ${theme.custom.headerHeight})`,
        padding: `3rem ${theme.spacing("l")} ${theme.spacing("l")} ${theme.spacing("l")}`
    },
    contentFullscreen: {
        left: 0,
        width: "100%",
        paddingRight: "0rem"
    },

    showTeaser: {
        top: "78px",
        height: "calc(100% - 78px)"
    }
}));
