/**
 * get options for slot interval
 * @return {Array<{label: string, value: Interval}>}
 */
const getOptionsSlotInterval = () => {
    return [
        {label: "App.slotInterval.none", value: "none"},
        {label: "App.slotInterval.weekdays", value: "weekdays"},
        {label: "App.slotInterval.daily", value: "daily"},
        {label: "App.slotInterval.weekly", value: "weekly"},
        // {label: t("App.slotInterval.monthly"), value: "monthly"},
        // {label: t("App.slotInterval.yearly"), value: "yearly"},
        {label: "App.slotInterval.user-defined", value: "user-defined"}
    ];
};

/**
 * get options for slot interval - lite version for room availabilities
 * @return {Array}
 */
const getOptionsSlotIntervalWithoutUserDefined = () => {
    return [
        {label: "App.slotInterval.none", value: "none"},
        {label: "App.slotInterval.weekdays", value: "weekdays"},
        {label: "App.slotInterval.daily", value: "daily"},
        {label: "App.slotInterval.weekly", value: "weekly"}
    ];
};

export {getOptionsSlotInterval, getOptionsSlotIntervalWithoutUserDefined};
