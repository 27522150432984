// @ts-check
/**
 * @fileoverview time range component
 *
 * usage:
 * <code>
 *     const {now, plusDT, minusDT} = useContext(DateContext);
 *     const {time, setTime} = useState({start: now(), end: plusDT(now(), "hour", 3)});
 *     const handleChangeTime = (date, name) => {
 *     setPeriod((prevState) => ({
 *             ...prevState,
 *             [name]: date
 *         }));
 *     };
 *
 *      return <TimeRange
 *          title={t("TimeslotDialog.period")}
 *          min={minusDT(now(), "year", 2)}
 *          max={plusDT(now(), "year", 10)}
 *          styles={{inputDate: classes.inputDate}}
 *          values={period}
 *       />
 * </code>
 */

import {bool, object, oneOfType, shape, string} from "prop-types";
import React, {Fragment, useContext} from "react";
import {useTranslation} from "react-i18next";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {ControlledDatePicker} from "../controlled_form/";
import useStyles from "../date_range/date_range.styles";

/**
 *
 * @typedef {("start"|"end")} StartOrEnd
 */
/**
 * @typedef Styles
 * @type {Object}
 * @property {String} [marginBetween]
 * @property {String} [inputDate]
 */
/**
 * TimeRange component
 * @param {Object} props
 * @param {Styles} [props.styles]
 * @param {{start: Boolean, end: Boolean}} [props.disabled]
 * @param {String} props.title
 * @param {Object} props.control
 * @param {Object} [props.errors]
 * @return {React.ReactElement}
 */
export const TimeRangeNew = ({styles, disabled, title, control, errors}) => {
    const {t} = useTranslation();
    const {classes, cx} = useStyles();
    const {getLuxonToken} = useContext(DateContext);

    const disables =
        typeof disabled === "boolean" || typeof disabled === "undefined"
            ? {
                  start: disabled,
                  end: disabled
              }
            : disabled;

    const timeRangeElement = ["start", "end"].map((/** @type StartOrEnd*/ startOrEnd) => {
        const label = startOrEnd === "start" ? title : "";
        return (
            <div
                className={cx({
                    [classes.marginBetween]: startOrEnd === "end",
                    [styles.marginBetween]: styles.marginBetween && startOrEnd === "end"
                })}
                key={"time-" + startOrEnd}
            >
                {startOrEnd === "end" && (
                    <div className={cx(classes.to, {[classes.disabled]: startOrEnd === "end" && disables.end})}>{t("App.to")}</div>
                )}
                <ControlledDatePicker
                    control={control}
                    disabled={disables[startOrEnd]}
                    disableOpenPicker
                    errors={errors[`timeRange_${startOrEnd}`]}
                    hasOwnErrorMessage={false}
                    inputFormat={getLuxonToken(DATE_FORMATS.TIME)}
                    label={label}
                    name={`timeRange_${startOrEnd}`}
                    startOrEnd={startOrEnd}
                    styles={styles}
                />
            </div>
        );
    });
    return <Fragment>{timeRangeElement}</Fragment>;
};

TimeRangeNew.propTypes = {
    styles: shape({
        marginBetween: string,
        inputDate: string
    }),
    disabled: oneOfType([
        bool,
        shape({
            start: bool,
            end: bool
        })
    ]),
    title: string.isRequired,
    control: object.isRequired,
    errors: object
};

TimeRangeNew.defaultProps = {
    styles: {}
};
