import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    title: {
        padding: "0 0 1rem",
        display: "flex",
        justifyContent: "space-between",
        textTransform: "capitalize",
        fontWeight: theme.typography.fontWeightRegular
    },
    encounterId: {
        "fontWeight": theme.typography.fontWeightMedium,
        "paddingRight": theme.spacing("s"),
        "&:after": {
            display: "inline",
            content: '"|"',
            marginLeft: "0.5rem"
        }
    },
    icon: {
        cursor: "pointer"
    }
}));
