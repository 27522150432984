// @ts-check
/**
 * @fileoverview time range component
 *
 * usage:
 * <code>
 *     const {now, plusDT, minusDT} = useContext(DateContext);
 *     const {time, setTime} = useState({start: now(), end: plusDT(now(), "hour", 3)});
 *     const handleChangeTime = (date, name) => {
 *     setTime((prevState) => ({
 *             ...prevState,
 *             [name]: date
 *         }));
 *     };
 *
 *      return <TimeRange
 *          title={t("TimeslotDialog.period")}
 *          min={minusDT(now(), "year", 2)}
 *          max={plusDT(now(), "year", 10)}
 *          styles={{inputDate: classes.inputDate}}
 *          values={time}
 *          onChange={handleChangePeriod}
 *       />
 * </code>
 */

import {TextField} from "@mui/material";
import {DatePicker as MuiDatePicker} from "@mui/x-date-pickers/DatePicker";
import PropTypes from "prop-types";
import React, {Fragment, useContext} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import config from "../../../../config/config.json";
import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {selectLanguage} from "../../../redux/app_selectors";
import useStyles from "./time_range.styles";

/**
 * @typedef Styles
 * @type {Object}
 * @property {String} [marginBetween]
 * @property {String} [inputDate]
 */
/**
 * TimeRange component
 * @param {Object} props
 * @param {Styles} [props.styles]
 * @param {{start: Boolean, end: Boolean}|Boolean} [props.disabled]
 * @param {String} props.title
 * @param {{start: DateTimeType, end: DateTimeType}} props.values DateTime object
 * @param {Function} props.onChange
 * @param {Boolean} [props.isDisableStartEndCheck]
 * @return {React.ReactElement}
 */
const TimeRange = ({styles, disabled, title, values, onChange, isDisableStartEndCheck}) => {
    const {t} = useTranslation();
    const {classes, cx} = useStyles();
    const {getDT, fromFormat} = useContext(DateContext);

    // redux store
    const language = useSelector(selectLanguage);

    // format
    const timeFormat = config.TIME_FORMAT_INPUT[language] || config.TIME_FORMAT_INPUT.de;

    const disables =
        typeof disabled === "boolean" || typeof disabled === "undefined"
            ? {
                  start: disabled,
                  end: disabled
              }
            : disabled;
    const endHour = getDT(values.end, "hour") + getDT(values.end, "minute") / 60;
    const startHour = getDT(values.start, "hour") + getDT(values.start, "minute") / 60;

    // if isDisableStartEndCheck, false
    // else if endHour <= startHour, then true, else false
    const isTimeRangeInvalid = isDisableStartEndCheck ? false : endHour <= startHour ? true : false;

    const handleBlur = (item) => (e) => {
        onChange(fromFormat(e.target.value, DATE_FORMATS.TIME), item);
    };
    const handleChange = (date, item) => {
        if (date?.isValid) {
            onChange(date, item);
        }
    };
    const timeRangeElement = ["start", "end"].map((item) => {
        let label = "";
        if (item === "start") label = title;
        return (
            <div
                className={cx({
                    [classes.marginBetween]: item === "end",
                    [styles.marginBetween]: styles.marginBetween && item === "end"
                })}
                key={"time-" + item}
            >
                {item === "end" && (
                    <div className={cx(classes.to, {[classes.disabled]: item === "end" && disables.end})}>{t("App.to")}</div>
                )}
                <MuiDatePicker
                    data-testid="timePickerForm"
                    disabled={disables[item]}
                    disableMaskedInput
                    disableOpenPicker={true}
                    // This property was used to show an error message under the input field
                    // But it broke the UI and it was not optimal. Need to be discussed where and what kind of messages to be show
                    // invalidDateMessage={<Fragment>{t("App.invalid")}</Fragment>}
                    inputFormat={timeFormat}
                    label={label}
                    renderInput={(props) => (
                        // @ts-ignore couldn't solve this :(
                        <TextField
                            variant="standard"
                            {...props}
                            classes={{
                                root: cx(classes.inputDate, {
                                    [styles.inputDate]: styles.inputDate,
                                    [classes.mtEnd]: item === "end"
                                })
                            }}
                            error={isTimeRangeInvalid || !values[item].isValid}
                            inputProps={{
                                // eslint-disable-next-line react/prop-types
                                ...props.inputProps,
                                "data-testid": item === "start" ? "timeRangeStart" : "timeRangeEnd"
                            }}
                            onBlur={handleBlur(item)}
                        />
                    )}
                    value={values[item]}
                    onChange={(date) => handleChange(date, item)}
                />
            </div>
        );
    });
    return <Fragment>{timeRangeElement}</Fragment>;
};

TimeRange.propTypes = {
    styles: PropTypes.shape({
        marginBetween: PropTypes.string,
        inputDate: PropTypes.string
    }),
    disabled: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            start: PropTypes.bool,
            end: PropTypes.bool
        })
    ]),
    values: PropTypes.shape({
        start: PropTypes.object, // DateTime object
        end: PropTypes.object // DateTime object
    }),
    onChange: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    isDisableStartEndCheck: PropTypes.bool
};

TimeRange.defaultProps = {
    styles: {}
};

export default TimeRange;
